/* review -vars */
$body: #333333;
$header: #212121;
$footer: #272727;

@at-root html {
  color: white;
  background-color: transparent !important;
  height: 100%;
}

body {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  min-height: 100%;
}

.overlay-overflow {
  /* width */
  &::-webkit-scrollbar {
    width: 20px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
  }
}

.browser-preview {
  width: 100%;
  height: 200px;
  border: 1px solid rgb(161, 158, 158);
  display: inherit;
  border-radius: 4px;
}
.body {
  padding-top: 20px;
  background-color: $body;
  background-image: url("https://i.ibb.co/gVf1z90/3px-tile.png");
  min-height: 90%;
  width: 100%;
  position: absolute;
}
.body > div {
  padding: 0px;
}
.header {
  background: $header;
  background-image: url("https://i.ibb.co/qnqt7j5/black-linen.png");
  padding: 4px;
  box-shadow: 5px 5px 5px 15px #111111;
}

.footer {
  box-shadow: 0px -2px 20px 10px #111111;
  position: fixed;
  bottom: 0;
  text-align: center;
  padding: 5px;
  margin: 0;
  background-color: $footer;
  background-image: url("https://i.ibb.co/qnqt7j5/black-linen.png");
  width: 100%;
}

.link {
  cursor: pointer;
  color: #f57c00;
  text-decoration: none;
  font-style: italic;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
}

.small {
  font-size: x-small;
}

.smashgg {
  cursor: pointer;
  text-decoration: none;
  font-size: large;
  font-weight: bolder;
  color: #e2444c;

  font-family: monospace;
  &:hover {
    color: #cb333b;
  }
}

.startgg {
  cursor: pointer;
  text-decoration: none;
  font-size: large;
  font-weight: bolder;
  color: #fff;
  text-shadow: 1px 1px black;
  font-style: italic;
  font-weight: bold;
  font-family: monospace;
  &:hover {
    color: #f57c00;
    text-decoration: underline;
  }
}

.content {
  background-color: #333333;
}

hr {
  border: 1px solid #2d2d2d;
}

.faded {
  color: #aaa;
}

.no-underline {
  text-decoration: none;
}

code {
  background: #333;
  border-radius: 4px;
  padding: 2px 4px;
}

.toast-spinner {
  width: 15px;
  height: 15px;
  vertical-align: middle;
  color: #f57c00;
}
